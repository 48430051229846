<template>
    <div v-if="json" id="sidepanel-mobile">
        <div class="textures abs100">
            <div class="textures-menu abs100">
                <div class="bgimg sidepanel-transition-sidenav" :style="{'background-image': 'url(' + require('@/assets/images/transitions/left-sidenav.jpg') + ')'}"></div>
            </div>
            <div class="textures-pages abs100">
                <div class="bgimg sidepanel-transition-video" :style="{'background-image': 'url(' + require('@/assets/images/transitions/left-video.jpg') + ')'}"></div>
                <div class="bgimg sidepanel-transition-about" :style="{'background-image': 'url(' + require('@/assets/images/transitions/left-about.jpg') + ')'}"></div>
                <div class="bgimg sidepanel-transition-shareables" :style="{'background-image': 'url(' + require('@/assets/images/transitions/left-shareables.jpg') + ')'}"></div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: "SidePanel",
    data () {
        return {
            window: window,
            json: null,
        }
    },
    computed: {
        ...mapState({
            language: state => state.language,
        }),
    },
    mounted () {
        this.json = this.window.config.json.copy[this.language]
    },
    watch: {
        language() {
            this.json = this.window.config.json.copy[this.language]
        }
    }

}
</script>
