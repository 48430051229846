<template>
    <div id="right-transition">
        <div class="mask-menu abs100">
            <div class="textures-menu abs100">
                <div class="bgimg right-transition-sidenav" :style="{'background-image': 'url(' + require('@/assets/images/transitions/right-sidenav.jpg') + ')'}"></div>
            </div>
        </div>
        <div class="mask-pages abs100">
            <div class="textures-pages abs100">
                <div class="bgimg right-transition-about" :style="{'background-image': 'url(' + require('@/assets/images/transitions/right-about.jpg') + ')'}"></div>
                <div class="bgimg right-transition-shareables" :style="{'background-image': 'url(' + require('@/assets/images/transitions/right-shareables.jpg') + ')'}"></div>
                <div class="bgimg right-transition-video" :style="{'background-image': 'url(' + require('@/assets/images/transitions/right-video.jpg') + ')'}"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RightTransition"
}
</script>
