<template>
    <div v-if="json" class="youtube-player">
        <div class="youtube-container">
            <YouTube
                v-if="doYTMount"
                :src="youTubeFullSource"
                :vars="youtubeParameters"
                className="ytplayerComponent"
                width="100%"
                height="100%"
                @ready="onReady"
                @error="onError"
                @state-change="onStateChange"
                @api-change="onApiChange"
                ref="youtube"
                controls
            />
            <div v-show="!isPlaying" @click="playVideo" class="video-play" :data-ytc="youTubeIsClickable" :data-loading="isLoading">
                <div class="bgimg cover-image" :style="{'background-image': 'url(' + this.coverImage + ')'}"></div>
                <span v-if="youTubeIsClickable && !isLoading" class="material-symbols-outlined big">play_circle</span>
            </div>
            <div v-show="isLoading" class="youtube-start abs100">
                <div class="item-loading">
                    <img :src="require('@/assets/images/global/loading.png')" />
                </div>
            </div>
            <div v-show="false && isPlaying" class="youtube-controls" >
                <div class="btn-pause" @click="pauseVideo">
                    <span class="material-icons">pause</span>
                </div>
                <div class="btn-sound" @click="muteToggle" >
                    <span v-if="isMuted" class="material-icons unmuted">volume_up</span>
                    <span v-if="!isMuted" class="material-icons muted">volume_off</span>
                </div>
                <div :id="scrubberId" class="scrubber" @click="seekTo">
                    <div class="bg-line"></div>
                    <div :style="fillLineStyle" class="fill-line"></div>
                    <div class="dragger"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// https://www.npmjs.com/package/vue3-youtube
// https://developers.google.com/youtube/player_parameters
import YouTube from 'vue3-youtube'
import { mapState } from 'vuex'
export default {
    name: "YouTubeCustom",
    components: {
        YouTube
    },
    props: {
        youTubeId: {
            type: String,
            required: true,
        },
        coverImage: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            window:window,
            document:document,
            scrubberId: 'youtube-player-scrubber-'+Date.now(),
            isLoading:false,
            isPlaying:false,
            isMuted:false,
            scrubberTimer: null,
            videoDuration: 0,
            videoCurrentTime: 0,

            json:null,

            doYTMount:false,
        }
    },
    computed: {
        ...mapState({
            youTubeIsClickable: state => state.youTubeIsClickable,
            language: state => state.language,
        }),
        youtubeParameters() {
            let ret = {
                rel: 0,
                fs: 1,
                controls: 1,
                modestbranding: 1,
                autohide: 1,
                playsinline: 1,
                enablejsapi: 1,
                autoplay:0,
                cc_load_policy:1,
                cc_lang_pref: (this.language === 'pt' || this.language === 'es') ? this.language : 'en',
                hl: (this.language === 'pt' || this.language === 'es') ? this.language : 'en'
            }
            return ret
        },
        youTubeFullSource() {
            return `https://www.youtube.com/watch?v=${this.youTubeId}`
        },
        fillLineStyle() {
            let pct = 0
            if (this.videoDuration>0) {
                pct = (this.videoCurrentTime / this.videoDuration) * 100
                //pct = this.videoCurrentTime / this.videoDuration
            }
            return `width:${pct}%`
        }
    },
    mounted () {
        this.json = this.window.config.json.copy[this.language]

        setTimeout(this.initialMountDelay,10)
        setTimeout(this.onReady,3000) //deep links on ready dont work with this component
    },
    watch: {
        isPlaying() {
            if (this.isPlaying) {
                //this.scrubberTimer = setInterval(this.scrubberTimerCallback, 250)
            } else{
                clearInterval(this.scrubberTimer)
            }
            this.$emit("playingEvent", this.isPlaying);
        }
    },
    methods: {
        initialMountDelay() {
            this.doYTMount=true
        },
        onApiChange() {
            /*
            if (this.$refs.youtube) {
                if (typeof this.$refs.youtube.setOption === 'function') {
                    this.$refs.youtube.setOption('captions', 'track', {languageCode: 'pt'}) // undocumented call
                }
            }
             */
        },
        onReady() {
            //this.videoDuration = this.$refs.youtube.getDuration()
            this.$store.commit('setYouTubeIsClickable')
        },
        onError(e) {
            console.log('onError', e)
        },
        onStateChange() {
            let playerState = this.$refs.youtube.getPlayerState()
            switch (playerState) {
                case -1: //unstarted
                    this.setEndedState()
                    break
                case 0: //ended
                    this.setEndedState()
                    if (this.$refs.youtube) {
                        this.$refs.youtube.stopVideo()
                    }
                    break
                case 1: //playing
                    this.setPlayingState()
                    break
                case 2: //paused
                    //this.setEndedState()  //UNDO IF CUSTOM CONTROl
                    break
                case 3: //buffering
                    this.setBufferingState() //UNDO IF CUSTOM CONTROl
                    break
                case 5: //video cued
                    break
            }
        },
        playVideo() {
            if (this.youTubeIsClickable) {
                if (this.$refs.youtube) {
                    try {
                        this.$refs.youtube.playVideo()
                    } catch (error) {
                        console.log('e', error)
                    }
                }
            }
        },
        pauseVideo() {
            this.setEndedState() //trigger early to prevent flashes
            this.$refs.youtube.pauseVideo()
        },
        muteToggle() {
            if (this.$refs.youtube.isMuted()) {
                this.$refs.youtube.unMute()
                this.isMuted = false
            } else {
                this.$refs.youtube.mute()
                this.isMuted = true
            }
        },
        scrubberTimerCallback() {
            this.videoCurrentTime = this.$refs.youtube.getCurrentTime()
        },
        seekTo(e) {
            const elem = this.document.getElementById(this.scrubberId)
            const left = e.offsetX
            const width = elem.offsetWidth
            const pct = left/width
            const seconds = pct * this.videoDuration
            this.$refs.youtube.seekTo(seconds, true)
        },

        setPlayingState() {
            //trigger late to prevent flashes
            let t = this
            setTimeout(function() {
                t.isPlaying = true
                t.isLoading = false
            }.bind(t),150)

        },
        setEndedState() {
            this.isPlaying = false
            this.isLoading = false
        },
        setBufferingState() {
            this.isLoading = true
        }
    },

}
</script>
