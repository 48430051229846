import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'
//import gh from '@/helpers/gh'

const routerHistory = createWebHistory()
const router = createRouter({
    history: routerHistory,
    routes: [], //defined globally in main
    scrollBehavior () {
        //scroll to top on all page changes
        return { top: 0 }
    }
})


router.beforeEach((to, from, next) => {
    if (window.config.loadedOnce === false) {
        //first load, just bring in
        window.config.loadedOnce = true
        next()
    } else {
        // all other loads, fade out old content then bring in
        //next()
        store.commit('routerNext', next)
        store.commit('triggerRouteTransition', to)
    }
})


router.afterEach((to) => {
    //language guard
    let path = String(to.path)
    let pathLang = path.substring(0,3)
    if (pathLang==='/pt') {
        store.commit('setLanguage', 'pt' )
    } else if (pathLang==='/es') {
        store.commit('setLanguage', 'es' )
    } else {
        store.commit('setLanguage', 'en' )
    }

    //set new title after gate
    let metaTitle = to.meta.title
    if (window.config.metaTitleAddon !== metaTitle) {
        if (to.meta.type!=='home') {
            metaTitle += (metaTitle) ? ' - ' + window.config.metaTitleAddon : window.config.metaTitleAddon
        }
    }
    document.title = metaTitle
    //gh.aosRefresh()
})
export default router
