import AOS from 'aos'
import createjs from 'preload-js'
import gsap, {Power3} from 'gsap'
const gh = {
    globalSectionInit: function() {
        setTimeout(function() {
            gh.aosInit()
        },500)
    },
    aosInit: function() {
        setTimeout(function() {
            AOS.init({
                offset: 40,
                duration: 800,
                once: true,
            })
            gh.aosRefresh()
        },100)

    },
    aosRefresh: function() {
        //AOS.refresh()
        AOS.refreshHard()
    },
    postLoadAssets: function(assets) {
        let queue = new createjs.LoadQueue()
        queue.setMaxConnections(100)
        //queue.on("progress", function(e) {console.log(e)}, this)
        //queue.on("complete", this.postloadComplete, this)
        //queue.on("error", this.postloadError, this)
        queue.loadManifest(assets)
    },
    scrollTo(route, window) {
        const tid = '#section-' + route
        gsap.to(window, {
            duration: 0.5,
            scrollTo:tid,
            easing: Power3.easeInOut
        })
    },
    enableBodyScroll(enable) {
        if (enable) {
            document.body.classList.remove("no-scroll")
            window.scrollTo(0, gh.bodyScrollY)
            document.body.style.top = 0
        } else {
            gh.bodyScrollY = window.scrollY
            document.body.classList.add("no-scroll")
            document.body.style.top = -gh.bodyScrollY+'px'
        }
    },
    stripSlashesFrontAndBack(val) {
        if (val) {
            val = val.toString()
            if(val.substr(-1) === '/') {
                val = val.substr(0, val.length - 1);
            }
            if(val.substr(0,1) === '/') {
                val = val.substr(1, val.length);
            }
        }
        return val

    },
    generateRoute(t, lang) {
        let ret = String(t.$route.fullPath)
        let retFirstThree = ret.substring(0,3)
        if (retFirstThree === '/pt' || retFirstThree === '/es') {
            ret = ret.substring(3)
        }
        if (ret.substring(0,1) === '/') {
            ret = ret.substring(1)
        }

        //console.log('ret', ret)
        switch ( lang) {
            case "Português":
                ret = `/pt/${ret}`
                break;
            case "Español":
                ret = `/es/${ret}`
                break;
            default:
            case "English":
                ret = `/${ret}`
                break;
        }
        return ret

    },
    generateLink(lang, link) {
        if (lang === 'en') {
            lang = `/`
        } else {
            lang = `/${lang}/`
        }
        return `${lang}${link}`
    }
}
export default gh
