<template>
    <section v-if="json" class="page page-video" >
        <div class="top-bar">
            <span v-html="json.video.topbar"></span>
            <div class="menu-close">
                <router-link :to="gh.generateLink(language,'')">
                    <div class="menu-close-inner">
                        <span class="seg seg1"></span>
                        <span class="seg seg2"></span>
                        <span class="dot"></span>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="denilson-fold">
            <div class="video-embed">
                    <YouTubeCustom :key="ytKey" @playingEvent="playingEvent" ref="ytPlayer" :youTubeId="json.video.ytId" :coverImage="require('@/assets/images/video/video-still.jpg')"></YouTubeCustom>
            </div>
            <div :class="['scroll-strip', (stripIsScrolled) ? 'scrolled' : '']">
                <div class="inner">
                    <div class="txt">{{json.scroll}}</div>
                    <div class="icon"><span></span></div>
                </div>
            </div>
        </div>
        <div class="denilson-about">
            <div class="rails-content">
                <div class="rail rail-left">
                    <div id="rail-left-items-Z" class="items">
                        <div class="item item-l">
                            <img :src="require('@/assets/images/video/rails-left.png')" />
                        </div>
                    </div>
                </div>
                <div  class="center-content">
                    <div id="scroll-hook" class="inner">
                        <div class="hooked-texts">
                            <div v-if="language==='en'" class="rte">
                                <blockquote data-aos="custom-fade-in">
                                    <p class="quote" >When I’m faced with a colonial narrative, I’m provoked to find in it what’s invisible to most people.</p>
                                    <p class="source"><small>—Denilson Baniwa</small></p>
                                </blockquote>
                                <p data-aos="custom-fade-in">From his studio in Rio de Janeiro, Baniwa uses his art to expose how colonial representations have erased the culture and denied the humanity of Indigenous Americans. </p>
                                <p data-aos="custom-fade-in">For <i>Reinventing the Américas</i>, Baniwa collaborated with the Getty Research Institute to generate video artworks, installations, and interventions on historical objects.</p>
                                <p data-aos="custom-fade-in">Connect with Baniwa on social media:</p>
                            </div>
                            <div v-if="language==='es'" class="rte">
                                <blockquote data-aos="custom-fade-in">
                                    <p class="quote" >Cuando me enfrento con una narrativa colonial, me incita a querer encontrar en esa narrativa lo que está oculto para la mayoría de las personas.</p>
                                    <p class="source"><small>—Denilson Baniwa</small></p>
                                </blockquote>
                                <p data-aos="custom-fade-in">Desde su estudio en Río de Janeiro, Baniwa usa su obra para exponer cómo las representaciones coloniales han borrado la cultura y negado la humanidad de los indígenas americanos.</p>
                                <p data-aos="custom-fade-in">Para <i>Reinventando las Américas</i>, Baniwa colaboró con el Getty Research Institute generando piezas de videoarte, instalaciones e intervenciones sobre objetos históricos.</p>
                                <p data-aos="custom-fade-in">Conéctese con Baniwa en las redes sociales:</p>
                            </div>
                            <div v-if="language==='pt'" class="rte">
                                <blockquote data-aos="custom-fade-in">
                                    <p class="quote" >Quando eu estou diante de uma narrativa colonial, eu me sinto provocado a querer encontrar naquela narrativa o que está oculto para a maioria das pessoas.</p>
                                    <p class="source"><small>—Denilson Baniwa</small></p>
                                </blockquote>
                                <p data-aos="custom-fade-in">Desde o seu ateliê no Rio de Janeiro, Baniwa usa a arte para expor como as representações coloniais apagaram a cultura e negaram a humanidade dos indígenas americanos.</p>
                                <p data-aos="custom-fade-in">Em <i>Reinventando as Américas</i>, Baniwa colaborou com o Getty Research Institute para gerar vídeo-arte, instalações e intervenções sobre objetos históricos.</p>
                                <p data-aos="custom-fade-in">Conecte-se com o Baniwa nas mídias sociais:</p>
                            </div>
                            <div data-aos="custom-fade-in" class="social-icon-holder">
                                <div class="social-icon instagram">
                                    <a href="https://www.instagram.com/denilsonbaniwa" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/icon-instagram.svg')" />
                                    </a>
                                </div>
                                <div class="social-icon youtube">
                                    <a href="https://www.youtube.com/c/denilsonbaniwa" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/icon-youtube.svg')" />
                                    </a>
                                </div>
                                <div class="social-icon behance">
                                    <a href="https://www.behance.net/denilsonbaniwa" target="_blank">
                                        <inline-svg :src="require('@/assets/images/global/icon-behance.svg')" />
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="mobile-bottom">
                        <img :src="require('@/assets/images/video/mobile-bottom.png')" />
                    </div>

                </div>
                <div class="rail rail-right">
                    <div id="rail-right-items-Z" class="items">
                        <div class="item item-r">
                            <img :src="require('@/assets/images/video/rails-right.png')" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>
<script>
import YouTubeCustom from "@/components/blocks/YouTubeCustom"
import { mapState } from 'vuex'
import gh from '@/helpers/gh'
//import gsap, {Power3} from 'gsap'
export default {
    name: "Video",
    components: {
        YouTubeCustom
    },
    data () {
        return {
            window: window,
            gh:gh,
            json: null,
            playingEventToggle:Math.random()
        }
    },
    mounted () {
        this.json = this.window.config.json.copy[this.language]
        gh.aosInit()
        setTimeout(this.reveal,100)
    },
    watch: {

    },
    computed: {
        ...mapState({
            language: state => state.language,
            scrolledAny: state => state.scrolledAny,
            youTubeIsClickable: state => state.youTubeIsClickable,
        }),
        ytKey() {
            if (this.youTubeIsClickable) {
                return 'ytyt-' + Math.random()
            } else {
                return 'ytyt-' + Math.random()
            }
        },
        stripIsScrolled() {
            let ret = false
            if (this.playingEventToggle) {
                ret = (this.scrolledAny || this.$refs.ytPlayer?.isPlaying)
            }
            return ret
        }
    },
    methods: {
        reveal() {

        },
        playingEvent() {
            this.playingEventToggle = Math.random()
        },
    }
}
</script>
