<template>
    <section v-if="json" class="page page-generic" >
        <div class="panel">
            <div class="top">
                <div class="top2">

                </div>
            </div>
            <div class="bottom">
                <div class="rte no-max-width" v-html="json.body"></div>
            </div>
        </div>

    </section>
</template>
<script>
import axios from 'axios'
import router from '@/router'
//import Parallax from 'parallax-js'
import gh from '@/helpers/gh'
export default {
    name: "Generic",
    components: {

    },
    data () {
        return {
            window: window,
            json: null,
            inPanelTransition: false,
        }
    },
    created () {

    },
    mounted () {
        const path = gh.stripSlashesFrontAndBack(router.currentRoute.value.path)
        const url = `${this.window.config.apiroot}${path}${this.window.config.apiext}`
        axios.get(url)
            .then((res) => {
                this.json = res.data
                setTimeout(this.reveal,10)
            }).catch(() => {
        })
    },
    methods: {
        reveal: function() {
            gh.aosInit()
        },
    }
}
</script>
