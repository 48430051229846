//REACTIVE
import { createStore } from 'vuex'

export const store = createStore({
    state () {
        return {
            popupOpen: false,
            popupObject:{},

            routeTransitionTo: null,
            routerNext: null,
            transitionType:'',
            didInitialSiteTransition:false,

            language: 'en',
            scrolledSome: false,
            scrolledAny: false,
            youTubeIsClickable: false,
        }
    },
    mutations: {
        triggerRouteTransition (state, to) {
            state.routeTransitionTo = to.meta.type
        },
        setTransitionType (state, val) {
            state.transitionType = val
        },
        setLanguage (state, val) {
            state.language = val
        },
        setScrolledSome (state, val) {
            state.scrolledSome = (val)
        },
        setScrolledAny (state, val) {
            state.scrolledAny = (val)
        },

        openPopup (state, obj) {
            state.popupObject = obj
            state.popupOpen = true
        },
        closePopup (state) {
            state.popupObject =
            state.popupOpen = false
        },

        routerNext(state, next) {
            state.routerNext = next
        },
        setDidInitialSiteTransition(state) {
            state.didInitialSiteTransition = true
        },
        setYouTubeIsClickable(state) {
            state.youTubeIsClickable = true
        }
    }
})
export default store
