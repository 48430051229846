<template>
    <header v-if="json" id="header">
        <div id="hamburger-shade"></div>
        <div class="left">
            <div id="hamburger" :class="(transitionType === 'sidenav') ? 'open' : 'closed'" @click="hamburgerToggle">
                <div class="lines">
                    <div class="line line1"></div>
                    <div class="line line2"></div>
                    <div class="line line3">
                        <div class="seg seg1"></div>
                        <div class="seg seg2"></div>
                    </div>
                </div>
            </div>
            <div id="language-picker">
                <div class="current">{{currentLanguage}}<span></span></div>
                <div class="options">
                    <ul>
                        <li @OLDclick="swapLanguage(lang)" v-for="lang in languages" :key="`li-${lang}`">
                            <a :href="gh.generateRoute(this, lang)" target="_self">{{lang}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="getty">
                <a :href="window.config.json.copy.global.linkout" target="_blank">
                    <img :src="require('@/assets/images/global/getty.png')" />
                </a>
            </div>
        </div>
        <div class="center">
            <div class="tt">
                <router-link :to="gh.generateLink(language,'')">
                    <img :src="require('@/assets/images/global/tt-' + language + '.png')" />
                </router-link>
            </div>
        </div>
    </header>
</template>

<script>
import { mapState } from 'vuex'
import gh from '@/helpers/gh'
export default {
    name: "Header",
    data () {
        return {
            window: window,
            gh:gh,
            json: null,
            languages: ['English', 'Español', 'Português' ]
        }
    },
    computed: {
        ...mapState({
            language: state => state.language,
            transitionType: state => state.transitionType,
        }),
        currentLanguage() {
            let ret = ''
            switch (this.language) {
                case "pt":
                    ret = "Português";
                    break;
                case "es":
                    ret = "Español";
                    break;
                default:
                case "en":
                    ret = "English";
                    break;
            }
            return ret
        }
    },
    watch: {

    },
    mounted () {
        this.json = this.window.config.json.copy[this.language]
    },
    methods: {
        hamburgerToggle() {
            if (this.transitionType !== 'sidenav') {
                this.$store.commit('setTransitionType', 'sidenav')
            } else {
                this.$store.commit('setTransitionType', '')
            }

        },
        swapLanguage(lang) {
            let ret = ''
            switch ( lang) {
                case "Português":
                    ret = "pt";
                    break;
                case "Español":
                    ret = "es";
                    break;
                default:
                case "English":
                    ret = "en";
                    break;
            }
            if (ret !== this.language) {
                this.$store.commit('setLanguage', ret)
            }
        },


    }
}
</script>
