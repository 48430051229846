<template>
    <section v-if="json" class="page page-about">
        <div class="bg abs100">
            <div class="bgimg">
                <div class="bgimg show-desktop"
                     :style="{'background-image': 'url(' + require('@/assets/images/global/bg-slate.jpg') + ')'}"></div>
                <div class="bgimg show-mobile"
                     :style="{'background-image': 'url(' + require('@/assets/images/global/bg-slate-mobile.jpg') + ')'}"></div>
            </div>
        </div>
        <div class="rails-content">
            <div class="rail rail-left">
                <div id="rail-left-items" class="items">
                    <div class="item item-l">
                        <img :src="require('@/assets/images/about/rail-l.png')"/>
                    </div>
                </div>
            </div>
            <div class="center-content">
                <div id="scroll-hook" class="inner">
                    <div class="hooked-texts">
                        <div v-if="language==='en'" class="rte">
                            <h1 data-aos="custom-fade-in">Plan Your Visit</h1>
                            <h4 data-aos="custom-fade-in">On view August 23, 2022 through January 8, 2023</h4>
                            <br>
                            <p data-aos="custom-fade-in"><b>Address</b><br>
                                <a href="https://www.google.com/maps/place/1200+Getty+Center+Dr,+Los+Angeles,+CA+90049" target="_blank">1200 Getty Center Dr<br>Los Angeles, CA 90049, USA</a>
                            </p>
                            <p data-aos="custom-fade-in">
                                <b>Hours</b><br>
                                Tuesday–Friday, Sunday: 10am–5:30pm<br>
                                Saturday: 10am–8pm<br>
                                Monday: Closed
                            </p>
                            <p data-aos="custom-fade-in">
                                <b>Tickets</b><br>
                                Admission is free, though requires a timed-entry reservation.
                            </p>
                            <p data-aos="custom-fade-in"><b>Parking</b><br>
                                Parking is $20 per car or motorcycle; $15 after 3pm. For evening events and on
                                Saturdays, parking is $10 after 6pm.
                            </p>
                            <p data-aos="custom-fade-in">
                                <br>
                                <a href="https://tickets.getty.edu/Online/default.asp?BOparam::WScontent::loadArticle::permalink=center&BOparam::WScontent::loadArticle::context_id=" target="_blank">Make a reservation</a>
                                <br>
                                <a href="https://www.getty.edu/research/exhibitions_events/exhibitions/reinventing_americas/index.html" target="_blank">Learn more about the exhibition</a>
                                <br><br>
                            </p>
                        </div>
                        <div v-if="language==='es'" class="rte">
                            <h1 data-aos="custom-fade-in">Planee su visita</h1>
                            <h4 data-aos="custom-fade-in">En exposición 23 de agosto del 2022 al 8 de enero del 2023</h4>
                            <br>
                            <p data-aos="custom-fade-in"><b>Dirección</b><br>
                                <a href="https://www.google.com/maps/place/1200+Getty+Center+Dr,+Los+Angeles,+CA+90049" target="_blank">1200 Getty Center Dr<br>Los Angeles, CA 90049, USA</a>
                            </p>
                            <p data-aos="custom-fade-in">
                                <b>Horario</b><br>
                                Martes–Viernes, Domingo: 10am–5:30pm<br>
                                Sábado: 10am–8pm<br>
                                Lunes: Cerrado
                            </p>
                            <p data-aos="custom-fade-in">
                                <b>Tickets</b><br>
                                La entrada es gratuita pero se requiere una reservación con horario marcado.
                            </p>
                            <p data-aos="custom-fade-in">
                                <b>Estacionamiento</b><br>
                                El estacionamiento cuesta $20 por coche o motocicleta; $15 después de las 3 pm. Los sábados el parking cuesta $10 después de las 6 pm.
                            </p>
                            <p data-aos="custom-fade-in">
                                <br>
                                <a href="https://tickets.getty.edu/Online/default.asp?BOparam::WScontent::loadArticle::permalink=center&BOparam::WScontent::loadArticle::context_id=" target="_blank">Haga su reservación</a>
                                <br>
                                <a href="https://www.getty.edu/research/exhibitions_events/exhibitions/reinventing_americas/index.html" target="_blank">Aprenda sobre la exposición</a>
                                <br><br>
                            </p>
                        </div>
                        <div v-if="language==='pt'" class="rte">
                            <h1 data-aos="custom-fade-in">Planeje sua visita</h1>
                            <h4 data-aos="custom-fade-in">Em exposição de 23 de agosto de 2022 a 8 de janeiro de 2023</h4>
                            <br>
                            <p data-aos="custom-fade-in"><b>Endereço</b><br>
                                <a href="https://www.google.com/maps/place/1200+Getty+Center+Dr,+Los+Angeles,+CA+90049" target="_blank">1200 Getty Center Dr<br>Los Angeles, CA 90049, USA</a>
                            </p>
                            <p data-aos="custom-fade-in">
                                <b>Horas</b><br>
                                De terça a sexta-feira e domingo: das 10 horas às 17h30<br>
                                Sábado: das 10 às 20 horas<br>
                                Segunda-feira: fechado
                            </p>
                            <p data-aos="custom-fade-in">
                                <b>Ingressos</b><br>
                                A entrada é gratuita, porém é necessário reservar com hora marcada.
                            </p>
                            <p data-aos="custom-fade-in">
                                <b>Estacionamento</b><br>
                                O estacionamento custa  20$ por carro ou moto;  15$ após as 15:00 horas. Para eventos noturnos e aos sábados, o estacionamento custa 10$ após as 18 horas.
                            </p>
                            <p data-aos="custom-fade-in">
                                <br>
                                <a href="https://tickets.getty.edu/Online/default.asp?BOparam::WScontent::loadArticle::permalink=center&BOparam::WScontent::loadArticle::context_id=" target="_blank">Faça uma reserva</a>
                                <br>
                                <a href="https://www.getty.edu/research/exhibitions_events/exhibitions/reinventing_americas/index.html" target="_blank">Saiba sobre a exposição</a>
                                <br><br>
                            </p>
                        </div>
                        <div class="mobile-bottom">
                            <img :src="require('@/assets/images/shareables/mobile-bottom.png')"/>
                        </div>
                    </div>

                </div>
            </div>
            <div class="rail rail-right">
                <div id="rail-right-items" class="items">
                    <div class="item item-r">
                        <img :src="require('@/assets/images/about/rail-r.png')"/>
                    </div>
                </div>
            </div>

        </div>
        <div class="menu-close">
            <router-link :to="gh.generateLink(language,'')">
                <div class="menu-close-inner">
                    <span class="seg seg1"></span>
                    <span class="seg seg2"></span>
                    <span class="dot"></span>
                </div>
            </router-link>
        </div>
    </section>
</template>
<script>
import {mapState} from 'vuex'
import gh from '@/helpers/gh'

export default {
    name: "About",
    components: {},
    data () {
        return {
            gh: gh,
            window: window,
            json: null,
        }
    },
    mounted () {
        this.json = this.window.config.json.copy[this.language]
        gh.aosInit()
    },
    computed: {
        ...mapState({
            language: state => state.language,
        }),
    },
    methods: {

    }
}
</script>
