<template>
    <section v-if="json" class="page fullheight page-home">
        <div class="bgs abs100">
            <div id="home-bgs" class="abs100">
                <div class="abs100 show-desktop">
                    <div id="home-bg-0" data-depth="0.12" class="bgimg" :style="{'background-image': 'url(' + require('@/assets/images/home/bg-0.jpg') + ')'}"></div>
                    <div id="home-bg-1"  data-depth="0.12" class="reveal-1 bgimg" :style="{'background-image': 'url(' + require('@/assets/images/home/bg-1.png') + ')'}"></div>
                    <div class="reveal-2 abs100">
                        <div id="home-bg-2"  data-depth="0.12" :class="['character-fader-hook bgimg', (scrolledAny) ? 'scrolled' : '']" :style="{'background-image': 'url(' + require('@/assets/images/home/bg-2.png') + ')'}"></div>
                    </div>

                </div>
                <div class="abs100 show-mobile">
                    <div id="home-bg-mobile-0" data-depth="0.12" class="bgimg tc" :style="{'background-image': 'url(' + require('@/assets/images/home/mobile-bg-1.jpg') + ')'}"></div>
                    <div class="reveal-2 abs100">
                        <div id="home-bg-mobile-1" data-depth="0.12" :class="['character-fader-hook tc bgimg', (scrolledAny) ? 'scrolled' : '']" :style="{'background-image': 'url(' + require('@/assets/images/home/mobile-bg-2.png') + ')'}"></div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="['exhibition-mobile', (scrolledAny) ? 'scrolled' : '']">
            <div class="rte" v-html="json.home.exhibition"></div>
        </div>
        <div class="rails-content">
            <div class="rail rail-left">
                <div id="rail-left-items" class="items">
                    <div class="spacer spacer-1"></div>
                    <div class="item item-1">
                        <img :src="require('@/assets/images/home/bg-l2-left-1.png')" />
                    </div>
                    <div class="item item-2">
                        <img :src="require('@/assets/images/home/bg-l2-left-2.png')" />
                    </div>
                </div>


            </div>
            <div  class="center-content">
                <div v-if="language==='en'" id="scroll-hook" class="inner home-inner">

                    <div class="hooked-texts">
                        <p class="hooky hooky-first"><br></p>
                    </div>
                    <div class="rte">
                        <p data-aos="custom-fade-in">Since 1492, European conquistadors, travelers, and artists have created images of the peoples of the Americas. Interpreting what they saw through their own cultural lens, explorers portrayed the continents as uncivilized, marvelous, and terrifying.</p>
                        <p data-aos="custom-fade-in">The story went that the New World was “discovered.” But in reality, this idea of the Americas was an invention—one based on European conventions.</p>
                        <p data-aos="custom-fade-in">Prints, books, and objects about the Americas circulated widely, fueling misconceptions and spreading stereotypes. </p>

                        <div data-aos="custom-fade-in" class="padded-image blue">
                            <img alt="A naked woman with long hair holds a spear in one hand and a human head in the other. Next to her feet is a parrot and a detached human leg." :src="require('@/assets/images/home/content-naked-original.jpg')" />
                        </div>
                        <figcaption data-aos="custom-fade-in">
                            <p data-aos="custom-fade-in"><i>Allegory of America</i>, from Philippe Galle, with verses by Cornelis Kiliaan, <i>Prosopographia</i> ([Antwerp?], 1600s), pl. 43. Getty Research Institute, <a href="http://primo.getty.edu/GRI:GETTY_ALMA21115866700001551" target="_blank">94-B3364</a></p>
                        </figcaption>


                        <p data-aos="custom-fade-in">“America” was frequently represented allegorically as a naked woman—often accompanied by symbols of cannibalism, as seen in the human head and leg in the image above. </p>
                        <p data-aos="custom-fade-in">For European viewers, America’s nudity indicated her savagery and distinguished her from their own Christian traditions. America—and by extension, its Indigenous peoples—were presented as objects of fascination and fear. This kind of symbolism persists in representations of Native peoples to this day.</p>
                        <p data-aos="custom-fade-in"><i>Reinventing the Américas</i> invites you to take a new look. The exhibition reframes colonial materials and gives voice to contemporary perspectives.</p>



                        <div data-aos="custom-fade-in" class="padded-image pink">
                            <img alt="A naked woman with long hair holds a spear in one hand and a human head in the other. Next to her feet is a parrot and a detached human leg. Behind her, painted in red over the illustration, are line drawings of geometric shapes. In the same manner, the white outline of a lizard is drawn over the limbs on the ground, a bird drawn in white on top of the parrot, a round shape drawn on top of the human head, and a white outline of lungs and intestines on top of the woman’s body." :src="require('@/assets/images/home/content-naked-remixed.jpg')" />
                        </div>
                        <figcaption data-aos="custom-fade-in">
                            <p data-aos="custom-fade-in"><i>No More America</i>, 2022, Denilson Baniwa. Digital intervention on <i>Allegory of America</i>, from Philippe Galle, with verses by Cornelis Kiliaan, <i>Prosopographia</i> ([Antwerp?], 1600s), pl. 43. Getty Research Institute, <a href="http://primo.getty.edu/GRI:GETTY_ALMA21115866700001551" target="_blank">94-B3364</a>. Courtesy the artist</p>
                        </figcaption>


                        <p data-aos="custom-fade-in">Indigenous and Latino/a members of the Los Angeles community were invited to provide commentary. Their words appear on the gallery walls.</p>

                        <blockquote data-aos="custom-fade-in">
                            <p class="quote">Most history is not reality, as it consists of both truth and imagination. That is how history is written, with a splash of truth and many lies...</p>
                            <p class="source"><small>—Salvador Álvarez, Arturo Cuevas, Federico Mora, and Efraín Pérez, Getty Grounds Staff Members</small></p>
                        </blockquote>

                        <p data-aos="custom-fade-in">In addition, Denilson Baniwa, an artist and member of the Baniwa Indigenous group from the state of Amazonas in Brazil, added his own visual interpretations to objects from the exhibition.</p>

                        <p data-aos="custom-fade-in">
                            <img alt="A man wearing narrow white eyewear stands facing the viewer against a black and brown geometrically patterned background." :src="require('@/assets/images/home/denilson-face.jpg')" /><br><br>
                        </p>
                        <figcaption data-aos="custom-fade-in">
                            <p data-aos="custom-fade-in">Self-portrait of Denilson Baniwa</p>
                        </figcaption>

                        <p data-aos="custom-fade-in">His contemporary interventions prompt us to see other sides to these historical representations. Learn about Baniwa’s artistic practice and see how he created works for this exhibition.</p>
                        <p data-aos="custom-fade-in">Watch <i>I am Denilson</i>:</p>
                        <div data-aos="custom-fade-in" class="rte">
                            <router-link :to="gh.generateLink(this.language, 'video')">
                                <div class="video-play">
                                    <img :src="require('@/assets/images/video/video-still.jpg')" />
                                    <span class="material-symbols-outlined">play_circle</span>
                                </div>
                            </router-link>
                        </div>
                        <figcaption data-aos="custom-fade-in">
                            <br><br>
                            <p data-aos="custom-fade-in">Run time: 10:08</p>
                        </figcaption>

                    </div>

                </div>
                <div v-if="language==='es'" id="scroll-hook" class="inner home-inner">

                    <div class="hooked-texts">
                        <p class="hooky hooky-first"><br></p>
                    </div>
                    <div class="rte">
                        <p data-aos="custom-fade-in">Desde 1492, conquistadores, viajeros y artistas europeos han creado imágenes de la gente de las Américas. Interpretando lo que veían  a través de su propia cultura, los exploradores retrataron el continente como incivilizado, maravilloso y aterrador.</p>
                        <p data-aos="custom-fade-in">Se decía que el Nuevo Mundo había sido “descubierto”. Pero en realidad, el concepto de las Américas fue una invención, basada en las convenciones de los europeos.</p>
                        <p data-aos="custom-fade-in">Grabados, libros y objetos sobre las Américas circularon ampliamente, alimentando ideas equivocadas y diseminando estereotipos.</p>

                        <div data-aos="custom-fade-in" class="padded-image blue">
                            <img alt="Una mujer desnuda con pelo largo sujeta una lanza en una mano y una cabeza humana en la otra. Al lado de sus pies hay un loro y una pierna humana amputada." :src="require('@/assets/images/home/content-naked-original.jpg')" />
                        </div>
                        <figcaption data-aos="custom-fade-in">
                            <p data-aos="custom-fade-in"><i>Allegory of America</i>, de Philippe Galle, con versos de Cornelis Kiliaan, <i>Prosopographia</i> ([Amberes?], siglo XVII), pl. 43. Getty Research Institute, <a href="http://primo.getty.edu/GRI:GETTY_ALMA21115866700001551" target="_blank">94-B3364</a></p>
                        </figcaption>

                        <p data-aos="custom-fade-in">“América” fue alegóricamente representada con frecuencia como una mujer desnuda, usualmente acompañada de símbolos de canibalismo, como se puede ver en la cabeza humana y la pierna en la imagen de arriba.</p>
                        <p data-aos="custom-fade-in">Para el público europeo, la desnudez de América indicaba su carácter salvaje, lo que la diferenciaba de las tradiciones cristianas. América, y por extensión la gente indígena, fueron presentados como objetos que provocaban fascinación y miedo. Este tipo de simbolismo persiste en las representaciones de los pueblos indígenas hoy en día.</p>
                        <p data-aos="custom-fade-in"><i>Reinventando las Américas</i> te invita a que mires con nuevos ojos. Esta exposición replantea materiales coloniales y da voz a perspectivas contemporáneas.</p>

                        <div data-aos="custom-fade-in" class="padded-image pink">
                            <img alt="Una mujer desnuda de pelo largo sostiene una lanza en una mano y una cabeza humana en la otra. Al lado de sus pies hay un loro y una pierna humana amputada. Detrás de ella, pintado en rojo sobre la ilustración, hay líneas de dibujos en formas geométricas. De la misma forma, el contorno blanco de un lagarto ha sido dibujado sobre los miembros del cuerpo en el suelo, un pájaro pintado en blanco sobre el loro, una forma redonda sobre la cabeza humana y un contorno blanco de unos pulmones e intestinos sobre el cuerpo de la mujer." :src="require('@/assets/images/home/content-naked-remixed.jpg')" />
                        </div>
                        <figcaption data-aos="custom-fade-in">
                            <p data-aos="custom-fade-in"><i>No más América (No More America)</i>, 2022, Denilson Baniwa. Intervención digital en <i>Alegoría de América</i>, de Philippe Galle, con versos de Cornelis Kiliaan, <i>Prosopographia</i> ([Amberes?], siglo XVII), pl. 43. Getty Research Institute, <a href="http://primo.getty.edu/GRI:GETTY_ALMA21115866700001551" target="_blank">94-B3364</a>. Cortesía del artista</p>
                        </figcaption>


                        <p data-aos="custom-fade-in">Miembros indígenas y latinos de la comunidad de Los Ángeles fueron invitados a dar sus opiniones. Sus palabras aparecen en las paredes de las galerías.</p>

                        <blockquote data-aos="custom-fade-in">
                            <p class="quote">La mayoría de la historia no es la realidad, parte es verdad y parte es imaginación. Y así se escribe la historia, de contar una milésima de verdad y muchas mentiras...</p>
                            <p class="source"><small>—Salvador Álvarez, Arturo Cuevas, Federico Mora, y Efraín Pérez, Jardineros del Getty</small></p>
                        </blockquote>

                        <p data-aos="custom-fade-in">Además, Denilson Baniwa, artista y miembro del grupo  indígena Baniwa del estado de Amazonas en Brasil, añadió sus propias interpretaciones visuales en los objetos de la exposición.</p>

                        <p data-aos="custom-fade-in">
                            <img alt="Un hombre que lleva unos lentes blancos estrechos mira al público frente a un fondo de estampado geométrico  negro y café." :src="require('@/assets/images/home/denilson-face.jpg')" /><br><br>
                        </p>
                        <figcaption data-aos="custom-fade-in">
                            <p data-aos="custom-fade-in">Autorretrato de Denilson Baniwa</p>
                        </figcaption>

                        <p data-aos="custom-fade-in">Sus intervenciones contemporáneas nos invitan a ver la otra cara de estas representaciones históricas. Conozca sobre la práctica artística de Baniwa y vea cómo creó las obras para esta exposición.</p>
                        <p data-aos="custom-fade-in">Vea <i>Yo soy Denilson</i>:</p>
                        <div data-aos="custom-fade-in" class="rte">
                            <router-link :to="gh.generateLink(this.language, 'video')">
                                <div class="video-play">
                                    <img :src="require('@/assets/images/video/video-still.jpg')" />
                                    <span class="material-symbols-outlined">play_circle</span>
                                </div>
                            </router-link>
                        </div>
                        <figcaption data-aos="custom-fade-in">
                            <br><br>
                            <p data-aos="custom-fade-in">Duración: 10:08</p>
                        </figcaption>

                    </div>

                </div>
                <div v-if="language==='pt'" id="scroll-hook" class="inner home-inner">

                    <div class="hooked-texts">
                        <p class="hooky hooky-first"><br></p>
                    </div>
                    <div class="rte">
                        <p data-aos="custom-fade-in">Desde 1492, conquistadores, viajantes e artistas europeus criaram imagens dos povos das Américas. Interpretando o que viam pelas lentes da própria cultura, os exploradores retrataram os continentes como incivilizados, maravilhosos e aterrorizantes.</p>
                        <p data-aos="custom-fade-in">Espalhou-se a notícia de que o Novo Mundo havia sido “descoberto”. Na verdade, essa ideia das Américas foi uma invenção — baseada em convenções europeias.</p>
                        <p data-aos="custom-fade-in">Gravuras, livros e objetos sobre as Américas circularam amplamente, alimentando equívocos e disseminando estereótipos.</p>

                        <div data-aos="custom-fade-in" class="padded-image blue">
                            <img alt="Uma mulher nua de cabelos longos segura uma lança em uma das mãos e uma cabeça humana na outra. Ao lado de seus pés há um papagaio e uma perna humana amputada." :src="require('@/assets/images/home/content-naked-original.jpg')" />
                        </div>
                        <figcaption data-aos="custom-fade-in">
                            <p data-aos="custom-fade-in"><i>Alegoria da América (Allegory of America)</i>, de Philippe Galle, com versos de Cornelis Kiliaan, <i>Prosopographia</i> ([Antuérpia?], século 17), pl. 43. Getty Research Institute, <a href="http://primo.getty.edu/GRI:GETTY_ALMA21115866700001551" target="_blank">94-B3364</a></p>
                        </figcaption>


                        <p data-aos="custom-fade-in">A “América” era frequentemente representada alegoricamente como uma mulher nua — muitas vezes acompanhada de símbolos de canibalismo, como se pode ver na cabeça e na perna humana na imagem acima.</p>
                        <p data-aos="custom-fade-in">Para o público europeu, a nudez da América indicava selvageria e os distinguia das próprias tradições cristãs europeias. A América e, por conseguinte, seus povos indígenas foram apresentados como objetos de fascínio e medo. Esse tipo de simbolismo persiste nas representações dos povos nativos até ao dia de hoje.</p>
                        <p data-aos="custom-fade-in"><i>Reinventando as Américas</i> convida-o a ver com novos olhos. A exposição ressignifica materiais coloniais e dá voz a perspectivas contemporâneas.</p>

                        <div data-aos="custom-fade-in" class="padded-image pink">
                            <img alt="Uma mulher nua de cabelos longos segura uma lança em uma das mãos e uma cabeça humana na outra. Ao lado de seus pés há um papagaio e uma perna humana amputada. Atrás dela, pintado de vermelho sobre a ilustração, há desenhos de linhas de formas geométricas. Da mesma maneira, o contorno branco de um lagarto é desenhado sobre os membros do corpo no chão, um pássaro desenhado em branco sobre o papagaio, uma forma redonda desenhada sobre a cabeça humana e um contorno branco de pulmões e intestinos sobre o corpo da mulher." :src="require('@/assets/images/home/content-naked-remixed.jpg')" />
                        </div>
                        <figcaption data-aos="custom-fade-in">
                            <p data-aos="custom-fade-in"><i>Não mais América (No More America)</i>, 2022, Denilson Baniwa. Intervenção digital em <i>Alegoria da América (Allegory of America)</i>, de Philippe Galle, com versos de Cornelis Kiliaan, Cornelis Kiliaan, <i>Prosopographia</i> ([Antuérpia?], século 17), pl. 43. Getty Research Institute, <a href="http://primo.getty.edu/GRI:GETTY_ALMA21115866700001551" target="_blank">94-B3364</a>. Cortesia do artista</p>
                        </figcaption>


                        <p data-aos="custom-fade-in">Membros indígenas e latinos da comunidade de Los Angeles foram convidados a fazer comentários. Suas palavras são exibidas nas paredes da galeria.</p>

                        <blockquote data-aos="custom-fade-in">
                            <p class="quote">A maior parte da história não é realidade, parte é verdade e parte é imaginação. É assim que a história é escrita, para dizer um milésimo de verdade e muitas mentiras...</p>
                            <p class="source"><small>—Salvador Álvarez, Arturo Cuevas, Federico Mora e Efraín Pérez, Membros da Equipe do Getty Grounds</small></p>
                        </blockquote>

                        <p data-aos="custom-fade-in">Denilson Baniwa, artista e membro do grupo indígena Baniwa do Estado do Amazonas no Brasil, acrescentou as próprias interpretações visuais aos objetos da exposição.</p>

                        <p data-aos="custom-fade-in">
                            <img alt="Um homem usando óculos brancos estreitos fica de frente para o público contra um fundo com padrões geométricos pretos e marrons." :src="require('@/assets/images/home/denilson-face.jpg')" /><br><br>
                        </p>
                        <figcaption data-aos="custom-fade-in">
                            <p data-aos="custom-fade-in">Autorretrato de Denilson Baniwa</p>
                        </figcaption>

                        <p data-aos="custom-fade-in">Essas intervenções contemporâneas nos convidam a ver outros lados dessas representações históricas. Conheça a prática artística de Baniwa e veja como ele criou as obras para esta exposição.</p>
                        <p data-aos="custom-fade-in">Assista a <i>Eu sou Denilson</i>:</p>
                        <div data-aos="custom-fade-in" class="rte">
                            <router-link :to="gh.generateLink(this.language, 'video')">
                                <div class="video-play">
                                    <img :src="require('@/assets/images/video/video-still.jpg')" />
                                    <span class="material-symbols-outlined">play_circle</span>
                                </div>
                            </router-link>
                        </div>
                        <figcaption data-aos="custom-fade-in">
                            <br><br>
                            <p data-aos="custom-fade-in">Duração: 10:08</p>
                        </figcaption>

                    </div>

                </div>
            </div>
            <div class="rail rail-right">
                <div id="rail-right-items" class="items">
                    <div class="spacer spacer-1"></div>
                    <div class="item item-2">
                        <img :src="require('@/assets/images/home/bg-l2-right-2.png')" />
                    </div>
                    <div class="item item-3">
                        <img :src="require('@/assets/images/home/bg-l2-right-3.png')" />
                    </div>
                    <div class="item item-4">
                        <img :src="require('@/assets/images/home/bg-l2-right-4.png')" />
                    </div>
                </div>
            </div>

        </div>
        <div :class="['scroll-strip', (scrolledAny) ? 'scrolled' : '']">
            <div class="inner">
                <div class="txt" >{{json.scroll}}</div>
                <div class="icon"><span></span></div>
            </div>
        </div>
    </section>
</template>
<script>
import gh from '@/helpers/gh'
//import { SplitText} from 'gsap/SplitText'
import { mapState } from 'vuex'
//import YouTubeCustom from "@/components/blocks/YouTubeCustom"
//import Parallax from 'parallax-js'
import gsap, {Power3} from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
export default {
    name: "Splash",
    components: {
        //Parallax
    },
    data () {
        return {
            window: window,
            document:document,
            gh:gh,
            json: null,
            inPanelTransition: false,
            parallaxInstanceBg:null,
            parallaxInstanceStairs:null,

            tme:0.75,

            //scroll related
            scrollTriggers: [],
            percentScrolled:0,
            leftRail:null,
            rightRail:null

        }
    },
    created() {

    },

    computed: {
        ...mapState({
            didInitialSiteTransition: state => state.didInitialSiteTransition,
            language: state => state.language,
            scrolledAny: state => state.scrolledAny,
        }),
    },
    mounted () {
        this.json = this.window.config.json.copy[this.language]
        setTimeout(this.reveal,10)
        this.window.addEventListener('resize', this.resize)
        this.window.addEventListener('scroll', this.scroll)
    },
    beforeUnmount() {
        for (let i = 0; i < this.scrollTriggers.length; i++) {
            if (this.scrollTriggers[i]) {
                this.scrollTriggers[i].kill()
            }
        }
    },
    unmounted() {
        this.window.removeEventListener("resize", this.resize)
        this.window.removeEventListener('scroll', this.scroll)
    },

    watch: {

    },
    methods: {
        reveal: function() {
            gh.aosInit()
            this.initScrollScene()
            this.inPanelTransition = true
            let tl = gsap.timeline({
                delay:0,
                //onComplete:this.revealInitialDone
            });

            tl.fromTo('.reveal-1',
                {
                    opacity: 0,
                    '-webkit-filter':'grayscale(100%)',
                    filter: 'grayscale(100%)',
                },
                {
                    opacity: 1,
                    '-webkit-filter':'grayscale(0%)',
                    filter: 'grayscale(0%)',
                    duration: this.tme,
                    ease: Power3.easeInOut,
                },
                0
            )
            tl.fromTo('.reveal-2',
                {
                    opacity: 0,
                    '-webkit-filter':'grayscale(100%)',
                    filter: 'grayscale(100%)',
                },
                {
                    opacity: 1,
                    '-webkit-filter':'grayscale(0%)',
                    filter: 'grayscale(0%)',
                    duration: this.tme,
                    ease: Power3.easeInOut,
                },
                0.25
            )

        },


        ///////SCROLL
        resize () {
            this.initScrollScene()
        },
        scroll() {
            let maxScroll = ScrollTrigger.maxScroll(this.window)

            this.percentScrolled = this.window.scrollY/maxScroll
            if (this.percentScrolled<0) {
                this.percentScrolled = 0
            } else if (this.percentScrolled>1) {
                this.percentScrolled=1
            }

            //
            if (this.leftRail && this.rightRail) {
                let windowHeight = this.window.innerHeight
                let leftRailHeight = this.leftRail.clientHeight - (windowHeight*1)
                let rightRailHeight = this.rightRail.clientHeight - (windowHeight*1)
                this.leftRail.style.top = (-this.percentScrolled * leftRailHeight) + "px"
                this.rightRail.style.top = (-this.percentScrolled * rightRailHeight) + "px"
            }
        },
        initScrollScene() {
            this.leftRail = this.document.getElementById('rail-left-items')
            this.rightRail = this.document.getElementById('rail-right-items')
        },
    }
}
</script>
