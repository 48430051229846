<template>
    <div id="container" :class="[(revealReady) ? 'opacity1' : 'opacity0', routeName, routeType, 'lang-'+language]">
        <Header></Header>
        <Menu></Menu>
        <SidePanel></SidePanel>
        <SidePanelMobile></SidePanelMobile>
        <RightTransition></RightTransition>
        <div id="main" :class="[(innerRevealReady) ? 'opacity1' : 'opacity0']">
            <transition name="fade" >
                <router-view :key="$route.fullPath"></router-view>
            </transition>
        </div>
        <!--
        <Footer :key="$route.fullPath" :class="[(innerRevealReady) ? 'opacity1' : 'opacity0']"></Footer>
        <RotateBlocker v-show="showBlocker"></RotateBlocker>
        -->
    </div>
</template>

<script>
import gh from '@/helpers/gh'
import Header from './components/framework/Header'
import SidePanel from './components/framework/SidePanel'
import SidePanelMobile from './components/framework/SidePanelMobile'
import RightTransition from './components/framework/RightTransition'
import Menu from './components/framework/Menu'
//import RotateBlocker from './components/framework/RotateBlocker'
import { isMobile, browserName } from 'mobile-device-detect'
import Preload from "image-preload"
import router from '@/router'

import { mapState } from 'vuex'
export default {
    name: 'App',
    components: {
        //RotateBlocker,
        //BGs,
        Header,
        SidePanel,
        SidePanelMobile,
        RightTransition,
        Menu
        //Footer
    },
    data() {
        return {
            window:window,
            document:document,
            showBlocker:false,
            revealReady:false,
            innerRevealReady:false,
            didInitialReveal:false,

            tme:0.75,

            zIndexSidepanelTextures:1,
        }
    },
    mounted() {
        // https://www.npmjs.com/package/mobile-device-detect
        const browser = String(browserName).toLowerCase()
        this.document.body.className += ' browser-'+ browser
        if (isMobile) {
            this.document.body.className += ' device-mobile'
        } else {
            this.document.body.className += ' device-notmobile'
        }

        this.window.addEventListener('resize', this.resize)
        this.window.addEventListener('scroll', this.scroll)
        this.resize()
        if (isMobile) {
            this.window.addEventListener('orientationchange', this.siteOrientationChange)
            this.siteOrientationChange()
        }

        //preload

        this.postload()
    },
    unmounted() {
        this.window.removeEventListener("resize", this.resize)
        this.window.removeEventListener('scroll', this.scroll)
    },
    computed: {
        ...mapState({
            transitionType: state => state.transitionType,
            routerNext: state => state.routerNext,
            routeTransitionTo: state => state.routeTransitionTo,
            language: state => state.language
        }),
        routeName() {
            const path = gh.stripSlashesFrontAndBack(router.currentRoute.value.path)
            return `route-${path}`
        },
        routeType() {
            const type = gh.stripSlashesFrontAndBack(router.currentRoute.value.meta.type)
            return `route-type-${type}`
        }
    },
    watch: {
        /*
        routeType(val, oldVal) {
            if (this.didInitialReveal) {
                this.innerRevealReady = false
            }
            let t = this
            setTimeout(function() {
                t.routeTypeChange(val, oldVal)
            }.bind(t, val, oldVal), 100)
        },
        */
        routeTransitionTo() {
            this.routeTransitionToCallback()
        },
        transitionType(val) {
            let body = this.document.querySelector("body")
            body.setAttribute("data-transition-type", val)
        }
    },
    methods: {
        postload() {
            this.revealReady = true
            this.innerRevealReady = true //TEMP zzz
            setTimeout(this.reveal, 10)

            //preload
            let preloadAssets = [
                require('@/assets/images/transitions/left-about.jpg'),
                require('@/assets/images/transitions/left-shareables.jpg'),
                require('@/assets/images/transitions/left-sidenav.jpg'),
                require('@/assets/images/transitions/left-video.jpg'),
                require('@/assets/images/transitions/right-about.jpg'),
                require('@/assets/images/transitions/right-shareables.jpg'),
                require('@/assets/images/transitions/right-sidenav.jpg'),
                require('@/assets/images/transitions/right-video.jpg'),
                require('@/assets/images/transitions/texture-about.jpg'),
                require('@/assets/images/transitions/texture-shareables.jpg'),

            ]
            if (isMobile) {
                //preloadAssets.push(require('@/assets/images/splash/corner-stairs-mobile.png'))
            } else {
                //preloadAssets.push(require('@/assets/images/splash/corner-stairs-desktop.png'))
            }

            Preload(preloadAssets, {
                //onComplete:this.reveal,
                onSingleImageFail:this.onSingleImageFail
            })
        },
        onSingleImageFail(e) {
            console.log('onSingleImageFail', e)
        },
        reveal() {
            //init language
            // TODO GET INITIAL LANGUAGE
            let ret = String(this.$route.fullPath)
            let retFirstThree = ret.substring(0,3)
            if (retFirstThree === '/pt' ) {
                this.$store.commit('setLanguage', 'pt')
            } else if (retFirstThree === '/es') {
                this.$store.commit('setLanguage', 'es')
            } else {
                this.$store.commit('setLanguage', 'en')
            }
        },


        resize () {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = this.window.innerHeight * 0.01;
            this.document.documentElement.style.setProperty('--vh', `${vh}px`)

            this.siteOrientationChange()
        },
        siteOrientationChange() {
            if (isMobile) {
                let t = this
                setTimeout(function () {
                    //console.log(t.window.innerWidth, t.window.innerHeight)
                    t.showBlocker = (t.window.innerWidth > t.window.innerHeight)
                    //if( (window.orientation === 90 && $( window ).width() < 780) || (window.orientation === -90 && $( window ).width() < 780) ) // Landscape {
                }.bind(t), 100)
            }
        },

        //transition
        routeTransitionToCallback() {
            let transitionStyle = this.routeTransitionTo
            if (transitionStyle === 'home') {
                transitionStyle = this.$route.meta.type
            }
            if (transitionStyle) {
                this.zIndexSidepanelTextures++
                let sel = 'sidepanel-transition-'+transitionStyle
                let collection = document.getElementsByClassName(sel)
                for (let i = 0; i < collection.length; i++) {
                    collection[i].style.zIndex = this.zIndexSidepanelTextures
                }

                this.$store.commit('setTransitionType', transitionStyle)
                setTimeout(this.routeTransitionToCallback2, 500)
            } else {
                this.routeTransitionToCallback2()
            }
        },

        //is now shut, so load in new, then pause a beat and then pull out
        routeTransitionToCallback2() {
            this.routerNext()
            setTimeout(this.routeTransitionToCallback3, 250)
        },
        routeTransitionToCallback3() {
            this.$store.commit('setTransitionType', '')
            //new scroll
            //setTimeout(this.initScrollScene,500)
        },

        //GLOBAL SCROLLERS
        scroll() {
            this.$store.commit('setScrolledSome', (this.window.scrollY>75))
            this.$store.commit('setScrolledAny', (this.window.scrollY>0))
        },


    }
}
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>
