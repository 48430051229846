<template>
    <div v-if="json" class="menu-outer abs100">
        <div class="menu">
            <div class="menu2">
                <ul>
                    <li v-for="item in json.subnav" :key="`limenu-${item.name}`">
                        <router-link @click="routerClick(item.link)" :to="item.link" v-html="item.name"></router-link>
                    </li>
                </ul>
                <!--
                <div class="social-icon-holder white">
                    <div class="social-icon fb">
                        <a href="https://www.facebook.com/gettymuseum" target="_blank">
                            <inline-svg :src="require('@/assets/images/global/icon-fb.svg')" />
                        </a>
                    </div>
                    <div class="social-icon instagram">
                        <a href="https://www.instagram.com/gettymuseum/" target="_blank">
                            <inline-svg :src="require('@/assets/images/global/icon-instagram.svg')" />
                        </a>
                    </div>
                    <div class="social-icon twitter">
                        <a href="https://twitter.com/gettymuseum" target="_blank">
                            <inline-svg :src="require('@/assets/images/global/icon-twitter.svg')" />
                        </a>
                    </div>
                </div>
                -->
                <div class="legal">
                    <div class="rte">
                        <small>Site by <a href="https://heroic.art" target="_blank">Heroic, LLC</a></small><br>
                        <small>©2022 J. Paul Getty Trust</small>
                    </div>

                </div>
            </div>
        </div>
        <div class="menu-close">
            <div class="menu-close-inner" @click="menuClose">
                <span class="seg seg1"></span>
                <span class="seg seg2"></span>
                <span class="dot"></span>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router'
import { mapState } from 'vuex'
export default {
    name: "Menu",
    data () {
        return {
            window: window,
            json: null,
        }
    },
    computed: {
        ...mapState({
            language: state => state.language,
        }),
    },
    mounted () {
        this.json = this.window.config.json.copy[this.language]
    },
    watch: {
        language() {
            this.json = this.window.config.json.copy[this.language]
        }
    },
    methods: {
        menuClose() {
            this.$store.commit('setTransitionType', '')
        },
        routerClick(link) {
            // close if clicking on own link
            if (link === router.currentRoute.value.path) {
                this.menuClose()
            }
        }
    }
}
</script>
