// FIRST detect language and route to appropriate path
// ONLY if we are landing on the english home page
import Cookies from 'js-cookie'
try {
    if (window?.location?.pathname === '' || window?.location?.pathname === '/') {
        if (Cookies.get('redirect') !== 'y') {
            let navLanguage = String(window.navigator.language)
            let navLanguageFirstTwo = navLanguage.substring(0, 2).toLowerCase()
            switch (navLanguageFirstTwo) {
                case "es":
                    Cookies.set('redirect', 'y', { expires: 1 })
                    window.location.href = "/es"
                    break
                case "pt":
                    Cookies.set('redirect', 'y', { expires: 1 })
                    window.location.href = "/pt"
                    break
                default:
                    break
            }
        }
    }
} catch(err) {
    console.log('autoredirect error', err)
}


import {createApp} from 'vue'
import App from './App.vue'
import axios from 'axios'

//globals
import InlineSvg from 'vue-inline-svg'

//state
import {store} from './store'

//routes
//import VueGtag from "vue-gtag-next"
import router from './router'

//templates
import Home from "@/components/pages/Home"
import Video from "@/components/pages/Video"
import About from "@/components/pages/About"
import Shareables from "@/components/pages/Shareables"
import Generic from "@/components/pages/Generic"

//vue touch events
import Vue3TouchEvents from "vue3-touch-events"

//GLOBAL CONFIG
/*
let craftApiPreviewToken = ''
if (window.siteConfig?.xCraftLivePreview) {
    craftApiPreviewToken = '?tokenParam=' + window.siteConfig?.xCraftLivePreview + '&token=' + window.siteConfig?.xCraftLivePreviewToken
}
 */
window.config = {
    metaTitleAddon: 'Reinventing the Americas. Construct. Erase. Repeat.',
    loadedOnce: true, //skip transition for now
    routerInTransition: false,
    apiroot: (window.localMode) ? '/api/' : '/api/',
    //apiext: (window.localMode) ? '.json' : craftApiPreviewToken,
    apiext: (window.localMode) ? '.json' : '.json',
    localMode: (window.localMode || window.siteConfig?.xCraftLivePreview),
    json: {
        global: null
    },
}
axios.get((`${window.config.apiroot}global${window.config.apiext}`))
    .then((res) => {
        //GLOBAL CONFIG
        window.config.json.global = res.data

        axios.get((`${window.config.apiroot}copy${window.config.apiext}`))
            .then((res2) => {
                window.config.json.copy = res2.data



                //DYNAMIC ROUTES
                for (let i = 0; i < res.data.routes.length; i++) {
                    let obj = res.data.routes[i]
                    let newobj = {
                        path: obj.path,
                        meta: {
                            title: obj.title,
                            type: obj.type
                        },
                    }
                    switch (obj.type) {
                        case 'home':
                            newobj.component = Home
                            break
                        case 'about':
                            newobj.component = About
                            break
                        case 'shareables':
                            newobj.component = Shareables
                            break
                        case 'video':
                            newobj.component = Video
                            break
                        case 'generic':
                            newobj.component = Generic
                            break
                    }
                    router.addRoute(newobj)
                }


                //page not found
                let newobj = {
                    name: 'page-not-found',
                    path: "/:catchAll(.*)",
                    //component: PageNotFound,
                    meta: {
                        title: '404'
                    }
                }
                router.addRoute(newobj)

                //START APP
                startApp()

            }).catch(() => {
        })


    }).catch(() => {
})

function startApp () {
    //LOAD
    const app = createApp(App)
    app.use(router)
    app.use(store)

    //vtouch events
    app.use(Vue3TouchEvents)

    //ANALYTICS - in your main.js, below loading your router
    if (!window.localMode) {
        /*
        app.use(VueGtag, {
            property: {
                id: window.config.json.global.config?.gaTag
            }
        })

         */
    }

    //global inline svg, font-awesome
    app.component('inline-svg', InlineSvg)
    app.mount('#app')
}
