<template>
    <section v-if="json" class="page page-shareables" >
        <div class="bg abs100">
            <div class="bgimg">
                <div class="bgimg show-desktop" :style="{'background-image': 'url(' + require('@/assets/images/global/bg-slate.jpg') + ')'}"></div>
                <div class="bgimg show-mobile" :style="{'background-image': 'url(' + require('@/assets/images/global/bg-slate-mobile.jpg') + ')'}"></div>
            </div>
        </div>
        <div class="rails-content">
            <div class="rail rail-left">
                <div id="rail-left-items" class="items">
                    <div class="item item-l">
                        <img :src="require('@/assets/images/shareables/rail-l.png')" />
                    </div>
                </div>
            </div>
            <div  class="center-content">
                <div id="scroll-hook" class="inner">
                    <div class="hooked-texts">
                        <div v-if="language==='en'" class="rte">
                            <h1 data-aos="custom-fade-in">Share on<br>Social Media</h1>
                            <p data-aos="custom-fade-in">Download images for screensavers, Zoom backgrounds, and social media.</p>
                        </div>
                        <div v-if="language==='es'" class="rte">
                            <h1 data-aos="custom-fade-in">Comparta en las redes sociales</h1>
                            <p data-aos="custom-fade-in">Baje imágenes para salvapantallas, fondos de Zoom y redes sociales.</p>
                        </div>
                        <div v-if="language==='pt'" class="rte">
                            <h1 data-aos="custom-fade-in">Compartilhe nas mídias sociais</h1>
                            <p data-aos="custom-fade-in">Faça o download das imagens para protetores de tela, fundos do Zoom e mídias sociais.</p>
                        </div>

                        <div class="shareable-content">
                            <div v-for="(item, index) in json.shareables.memes" class="item" data-aos="custom-fade-in"  :key="`meme-${item.name}`">
                                <div @click="tapHandler(index)" :class="itemClass(item, index)" >
                                    <img class="item-img" :src="item.thumb" />
                                    <div  class="overlay">
                                        <div class="cols">
                                            <div @click="downloadItem(item.path1, item.path1Filename)" class="col">
                                                <div class="icon-outer">
                                                    <div :class="`icon ${item.path1Label}`"></div>
                                                </div>
                                                <div class="iconarrow"><inline-svg :src="require('@/assets/images/shareables/download.svg')" /></div>

                                                <div class="meta"><img :src="item.path1" /></div>
                                            </div>
                                            <div v-if="item.path2" @click="downloadItem(item.path2, item.path2Filename)" class="col">
                                                <div class="icon-outer">
                                                    <div :class="`icon ${item.path2Label}`"></div>
                                                </div>
                                                <div class="iconarrow"><inline-svg :src="require('@/assets/images/shareables/download.svg')" /></div>
                                                <div class="meta"><img :src="item.path2" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div v-if="1 === 2" class="rte">
                            <div v-if="language==='en'" class="other-links">
                                <div class="jaguar">
                                    <img class="" :src="require('@/assets/images/shareables/mask.png')" />
                                </div>
                                <p data-aos="custom-fade-in">
                                    <a href="http://google.com" target="_blank">Try on jaguar Instagram mask</a>
                                </p>
                                <p data-aos="custom-fade-in">
                                    <a href="https://giphy.com/gettymuseum" target="_blank">Share exhibition GIFs</a>
                                </p>
                            </div>
                            <div v-if="language==='es'" class="other-links">
                                <div class="jaguar">
                                    <img class="" :src="require('@/assets/images/shareables/mask.png')" />
                                </div>
                                <p data-aos="custom-fade-in">
                                    <a href="http://google.com" target="_blank">Póngase la máscara de jaguar de Instagram</a>
                                </p>
                                <p data-aos="custom-fade-in">
                                    <a href="https://giphy.com/gettymuseum" target="_blank">Comparta GIFs de la exposición </a>
                                </p>
                            </div>
                            <div v-if="language==='pt'" class="other-links">
                                <div class="jaguar">
                                    <img class="" :src="require('@/assets/images/shareables/mask.png')" />
                                </div>
                                <p data-aos="custom-fade-in">
                                    <a href="http://google.com" target="_blank">Experimente a máscara "jaguar" no Instagram</a>
                                </p>
                                <p data-aos="custom-fade-in">
                                    <a href="https://giphy.com/gettymuseum" target="_blank">Compartilhe os GIFs da exposição</a>
                                </p>
                            </div>
                        </div>

                        <div data-aos="custom-fade-in" class="rte">
                            <p v-if="language==='en'"><br>Follow Getty on social media:</p>
                            <p v-if="language==='es'"><br>Sigue a Getty en las redes sociales:</p>
                            <p v-if="language==='pt'"><br>Siga a Getty nas mídias sociais:</p>
                        </div>
                        <div data-aos="custom-fade-in" class="social-icon-holder white">

                            <div class="social-icon fb">
                                <a href="https://www.facebook.com/gettymuseum" target="_blank">
                                    <inline-svg :src="require('@/assets/images/global/icon-fb.svg')" />
                                </a>
                            </div>
                            <div class="social-icon instagram">
                                <a href="https://www.instagram.com/gettymuseum/" target="_blank">
                                    <inline-svg :src="require('@/assets/images/global/icon-instagram.svg')" />
                                </a>
                            </div>
                            <div class="social-icon twitter">
                                <a href="https://twitter.com/gettymuseum" target="_blank">
                                    <inline-svg :src="require('@/assets/images/global/icon-twitter.svg')" />
                                </a>
                            </div>
                            <br>
                            <br>
                        </div>
                        <div data-aos="custom-fade-in" class="rte">
                            <p v-if="language==='en'"><br><small>Spirit Animals, 2022, Denilson Baniwa. © Denilson Baniwa. Courtesy the artist</small></p>
                            <p v-if="language==='es'"><br><small>Espíritus animales, 2022, Denilson Baniwa. © Denilson Baniwa. Cortesía del artista</small></p>
                            <p v-if="language==='pt'"><br><small>Espíritos animais, 2022, Denilson Baniwa.  © Denilson Baniwa. Cortesia do artista</small></p>
                        </div>
                        <div class="mobile-bottom">
                            <img :src="require('@/assets/images/shareables/mobile-bottom.png')" />
                        </div>
                    </div>

                </div>
            </div>
            <div class="rail rail-right">
                <div id="rail-right-items" class="items">
                    <div class="item item-r">
                        <img :src="require('@/assets/images/shareables/rail-r.png')" />
                    </div>
                </div>
            </div>

        </div>
        <div class="menu-close">
            <router-link :to="gh.generateLink(language,'')">
                <div class="menu-close-inner">
                    <span class="seg seg1"></span>
                    <span class="seg seg2"></span>
                    <span class="dot"></span>
                </div>
            </router-link>
        </div>
    </section>
</template>
<script>
import { mapState } from 'vuex'
import gh from '@/helpers/gh'
import axios from 'axios'
axios.defaults.withCredentials = true
export default {
    name: "About",
    components: {

    },
    data () {
        return {
            gh:gh,
            window: window,
            json: null,
            itemTapped:null,
        }
    },
    mounted () {
        this.json = this.window.config.json.copy[this.language]
        gh.aosInit()
    },
    computed: {
        ...mapState({
            language: state => state.language,
        }),
    },
    methods: {
        downloadItem (url, filename) {
            axios.get(url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/jpg' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = filename
                    link.click()
                    URL.revokeObjectURL(link.href)
                }).catch(console.error)
        },
        tapHandler(index) {
            if (index === this.itemTapped) {
                this.itemTapped = null
            } else {
                this.itemTapped = index
            }
        },
        itemClass(item, index) {
            let ret = 'item2'
            if (index === this.itemTapped) {
                ret += ' tapped'
            }
            return ret
        }
    }
}
</script>
